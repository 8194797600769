<template>
    <div class="events">
        <div class="container">
            <div v-if="loading" class="first-event">
                <div class="first-event-body">
                    <div class="first-event-img">
                        <b-skeleton width="100%" height="100%"></b-skeleton>
                    </div>
                    
                    <div class="first-event-content">
                        <div class="d-flex align-items-start">
                            <button class="btn p-0">
                                <h4 class="text-brand mb-1 font-weight-600">
                                    <b-skeleton width="12rem" height="1.5rem"></b-skeleton>
                                </h4>
                            </button>
                        </div>
                        <small class="d-block text-gray mb-2">
                            <b-skeleton width="10rem" height="1rem"></b-skeleton>
                        </small>
                        <p class="text mb-4">
                            <b-skeleton width="100%" height="5rem">
                        </b-skeleton></p>
                        <div
                            class="
                                d-flex
                                flex-column flex-sm-row
                                align-items-start align-items-sm-center
                                justify-content-start justify-content-sm-between
                            "
                        >
                            <div class="d-flex align-items-center">
                                <div class="partner-img rounded-circle overflow-hidden">
                                    <b-skeleton width="100%" height="100%"></b-skeleton>
                                </div>
                                <h6 class="mx-2 mb-0 font-weight-600">
                                    <b-skeleton width="10rem" height="1.5rem"></b-skeleton>
                                </h6>
                            </div>
                            <div class="d-flex align-items-center">
                                <b-skeleton width="12rem" height="1.5rem"></b-skeleton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else-if="!loading && firstEvent" class="first-event">
                <div class="first-event-body">
                    <button class="btn p-0 w-100 h-100" @click="checkLogin(firstEvent.id, firstEvent.title)">
                        <img
                            class="first-event-img"
                            :src="firstEvent.photo"
                            :alt="firstEvent.title"
                        />
                        <!-- <progressive-img
                            class="first-event-img"
                            :src="firstEvent.photo"
                            :alt="firstEvent.title"/> -->
                    </button>
                    <div class="first-event-content">
                        <div class="d-flex align-items-start">
                            <button
                            class="btn p-0"
                            @click="checkLogin(firstEvent.id, firstEvent.title)">
                                <h4 class="text-brand mb-1 font-weight-600">
                                    {{ firstEvent.title }}
                                </h4>
                            </button>
                        </div>
                        <small class="d-block text-gray mb-2">
                            {{ firstEvent.business_unit }}
                        </small>
                        <p class="text">{{ textSummary(firstEvent.body, 250) }}</p>
                        <div
                            class="
                                d-flex
                                flex-column flex-sm-row
                                align-items-start align-items-sm-center
                                justify-content-start justify-content-sm-between
                            "
                        >
                            <div class="d-flex align-items-center">
                                <img
                                    class="partner-img rounded-circle"
                                    :src="firstEvent.partner_logo"
                                    :alt="firstEvent.partner"
                                />
                                <h6 class="mx-2 mb-0 font-weight-600">
                                    {{ firstEvent.partner }}
                                </h6>
                            </div>
                            <div class="d-flex align-items-center">
                                <div class="d-flex align-items-center mx-2 whitespace-nowrap">
                                    <span class="mx-1">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="1.1rem"
                                            height="1.1rem"
                                            viewBox="0 0 18 20"
                                        >
                                            <path
                                                d="M10.5,12h-2v2h2Zm4,0h-2v2h2Zm4,0h-2v2h2Zm2-7h-1V3h-2V5h-8V3h-2V5h-1A1.991,1.991,0,0,0,4.51,7L4.5,21a2,2,0,0,0,2,2h14a2.006,2.006,0,0,0,2-2V7A2.006,2.006,0,0,0,20.5,5Zm0,16H6.5V10h14Z"
                                                transform="translate(-4.5 -3)"
                                                fill="#959595"
                                            />
                                        </svg>
                                    </span>
                                    <span class="text-gray">
                                {{ $moment(firstEvent.time).format("YYYY-MM-DD") }}
                                    </span>
                                </div>
                                <div class="d-flex align-items-center whitespace-nowrap">
                                    <span class="mx-1">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="1.1rem"
                                            height="1.1rem"
                                            viewBox="0 0 19.5 19.5"
                                        >
                                            <path
                                                d="M13.116,3.375a9.75,9.75,0,1,0,9.759,9.75A9.747,9.747,0,0,0,13.116,3.375Zm.009,17.55a7.8,7.8,0,1,1,7.8-7.8A7.8,7.8,0,0,1,13.125,20.925Z"
                                                transform="translate(-3.375 -3.375)"
                                                fill="#959595"
                                            />
                                            <path
                                                d="M18,10.688H16.538v5.85l5.119,3.07.731-1.2L18,15.806Z"
                                                transform="translate(-7.763 -5.813)"
                                                fill="#959595"
                                            />
                                        </svg>
                                    </span>
                                    <span class="text-gray">
                                {{ $moment(firstEvent.time).format("hh:mm A") }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Events  -->
            <div class="d-flex flex-column flex-xl-row py-3">
                <div v-if="loading" class="events-body order-2 order-xl-1">
                    <div 
                        v-for="(event, index) in 2"
                        :key="index"
                        class="event mb-3">
                        <div
                            class="
                                event-content
                                d-flex
                                flex-column flex-lg-row
                                align-items-start align-items-lg-center
                            ">
                            <button class="btn pt-sm-3 px-sm-3 p-lg-0">
                                <div class="event-img">
                                    <b-skeleton width="100%" height="200px"></b-skeleton>
                                </div>
                            </button>
                            <div class="event-content-body">
                                <div class="d-flex">
                                    <h5 class="font-weight-600 mb-1">
                                    <b-skeleton width="10rem" height="1.5rem"></b-skeleton>
                                    </h5>
                                </div>
                                <span class="text-gray d-block mb-3">
                                <b-skeleton width="7rem" height="0.8rem"></b-skeleton>
                                </span>
                                <p class="text">
                                    <b-skeleton width="100%" height="4rem"></b-skeleton>
                                </p>
                                <div
                                    class="d-flex flex-column flex-sm-row align-items-start align-items-sm-center justify-content-start justify-content-sm-between">
                                    <div class="d-flex align-items-center mb-2 mb-sm-0">
                                        <div class="partner-img rounded-circle overflow-hidden">
                                            <b-skeleton width="100%" height="100%"></b-skeleton>
                                        </div>
                                        <h6 class="mx-2 mb-0 font-weight-600">
                                            <b-skeleton width="11rem" height="1rem"></b-skeleton>
                                        </h6>
                                    </div>
                                    <div class="d-flex align-items-center mx-3">
                                        <b-skeleton width="11rem" height="1rem"></b-skeleton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else-if="!loading && !firstEvent" 
                class="events-body order-2 order-xl-1">
                    <div class="event mb-3">
                        <div class="event-content text-center py-4 font-weight-bold">
                            {{ $t('noResults') }}
                        </div>
                    </div>
                </div>
                <div v-else-if="!loading && firstEvent" class="events-body order-2 order-xl-1">
                    <div
                        v-for="(event, index) in events"
                        :key="index"
                        class="event mb-3">
                        <div
                            class="
                                event-content
                                d-flex
                                flex-column flex-lg-row
                                align-items-start align-items-lg-center
                            ">
                            <button
                                @click="checkLogin(event.id, event.title)"
                                class="btn pt-sm-3 px-sm-3 p-lg-0"
                            >
                                <!-- <img
                                    class="event-img"
                                    :src="event.photo"
                                    :alt="event.title"
                                /> -->
                                <progressive-img
                                    class="event-img"
                                    :src="event.photo"
                                    :alt="event.title"/>
                            </button>
                            <div class="event-content-body">
                                <div class="d-flex">
                                    <button 
                                    class="btn p-0"
                                    @click="checkLogin(event.id, event.title)">
                                        <h5 class="font-weight-600 mb-1">
                                            {{ event.title }}
                                        </h5>
                                    </button>
                                </div>
                                <small class="text-gray d-block mb-3">
                                    {{ event.business_unit }}
                                </small>
                                <p class="text">
                                    {{ textSummary(event.body, 120) }}
                                </p>
                                <div
                                    class="
                                        d-flex
                                        flex-column flex-sm-row
                                        align-items-start align-items-sm-center
                                        justify-content-start
                                        justify-content-sm-between
                                    ">
                                    <div
                                        class="
                                            d-flex
                                            align-items-center
                                            mb-2 mb-sm-0
                                        ">
                                        <img class="partner-img rounded-circle"  :src="event.partner_logo" :alt="event.partner"/>
                                        <h6 class="mx-2 mb-0 font-weight-600">
                                            {{ event.partner }}
                                        </h6>
                                    </div>
                                    <div class="d-flex align-items-center">
                                        <div
                                            class="
                                                d-flex
                                                align-items-center
                                                mx-2
                                                whitespace-nowrap
                                            ">
                                            <span class="mx-1">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="1.1rem"
                                                    height="1.1rem"
                                                    viewBox="0 0 18 20"
                                                >
                                                    <path
                                                        d="M10.5,12h-2v2h2Zm4,0h-2v2h2Zm4,0h-2v2h2Zm2-7h-1V3h-2V5h-8V3h-2V5h-1A1.991,1.991,0,0,0,4.51,7L4.5,21a2,2,0,0,0,2,2h14a2.006,2.006,0,0,0,2-2V7A2.006,2.006,0,0,0,20.5,5Zm0,16H6.5V10h14Z"
                                                        transform="translate(-4.5 -3)"
                                                        fill="#959595"
                                                    />
                                                </svg>
                                            </span>
                                            <span class="text-gray">
                                                {{ $moment(event.time).format("YYYY-MM-DD") }}
                                            </span>
                                        </div>
                                        <div class="d-flex align-items-center whitespace-nowrap">
                                            <span class="mx-1">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="1.1rem"
                                                    height="1.1rem"
                                                    viewBox="0 0 19.5 19.5"
                                                >
                                                    <path
                                                        d="M13.116,3.375a9.75,9.75,0,1,0,9.759,9.75A9.747,9.747,0,0,0,13.116,3.375Zm.009,17.55a7.8,7.8,0,1,1,7.8-7.8A7.8,7.8,0,0,1,13.125,20.925Z"
                                                        transform="translate(-3.375 -3.375)"
                                                        fill="#959595"
                                                    />
                                                    <path
                                                        d="M18,10.688H16.538v5.85l5.119,3.07.731-1.2L18,15.806Z"
                                                        transform="translate(-7.763 -5.813)"
                                                        fill="#959595"
                                                    />
                                                </svg>
                                            </span>
                                            <span class="text-gray">
                                            {{ $moment(event.time).format("hh:mm A") }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- pagination -->
                    <div
                        v-if="lastPageID - 1"
                        class="
                            d-flex
                            justify-content-center
                            pt-4
                            pb-1
                            mb-5
                            overflow-hidden
                        ">
                        <nav aria-label="Page navigation">
                            <ul class="pagination">
                                <li v-if="!isFirstPage" class="page-item">
                                    <button 
                                    @click="fetchPage('prev')"
                                    class="page-link">
                                    {{ $t('prev') }}
                                    </button>
                                </li>
                                <li v-for="(page, index) in pagesLinks" :key="index"
                                class="page-item">
                                    <button
                                        @click="fetchPage(page.id)"
                                        :class="[currentPage == page.id ? 'active' : '']"
                                        :disabled="currentPage == page.id"
                                        class="page-link">{{ page.id }}</button
                                    >
                                </li>
                                <li v-if="!isLastPage" class="page-item">
                                    <button 
                                    @click="fetchPage('next')"
                                    class="page-link">
                                    {{ $t('next') }}
                                    </button>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
                <aside class="events-sidebar order-1 order-xl-2">
                    <h6 class="font-weight-600">Filter</h6>
                    <div class="py-3">
                        <EventsFilter @filter="getAllEvents($event)"/>
                        <div class="pt-3">
                            <div v-if="loadingSuggested" class="suggested-event">
                                <b-skeleton width="100%" height="100%"></b-skeleton>
                                <h4 class="title loading text-uppercase d-flex flex-column mb-0 text-center">
                                    <b-skeleton width="10rem" height="6rem"></b-skeleton>
                                </h4>
                            </div>
                            <div v-else>
                                <router-link
                                    :to="`/events/${ suggested.id }/${ suggested.title }`"
                                    class="suggested-event d-block">
                                    <img
                                        :src="suggested.photo"
                                        :alt="suggested.title"
                                    />
                                    <!-- <progressive-img
                                    :src="suggested.photo"
                                    :alt="suggested.title"/> -->
                                    <h4
                                        class="
                                            title
                                            text-uppercase
                                            d-flex
                                            flex-column
                                            mb-0
                                            text-center
                                        "
                                    >
                                        Suggested
                                        <span class="text-white d-inline-block"
                                            >event</span
                                        >
                                    </h4>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </aside>
            </div>
        </div>
    </div>
</template>

<script>
import { getEvents, getSuggestedEvent } from "@/API/events";
import EventsFilter from "@/components/EventsFilter";
import pagination from "@/mixins/pagination";
import textSummary from "@/mixins/textSummary";

export default {
    name: "Events",
    mixins: [pagination, textSummary],
    components: {
        EventsFilter,
    },
    data() {
        return {
            loading: true,
            loadingSuggested: true,
            currentPage: 1,
            isFirstPage: true,
            isLastPage: true,
            lastPageID: 1,
            pagesLinks: [],
            firstEvent: null,
            events: [],
            suggested: {},
            filters: null,
        };
    },
    methods: {
        getAllEvents(filters) {
            this.loading = true;

            if (filters) {
                this.currentPage = 1;
                this.filters = filters;
            };

            getEvents(this.currentPage, this.filters).then(res => {
                this.loading = false;
                if (res.status == 200) {
                    const data = res.data?.data;
                    // console.log(data);

                    this.firstEvent = data?.event?.find((ev, index) => index == 0);

                    this.events = data?.event?.filter((ev, index) => index != 0);

                    if (data?.paginate) {
                        this.pagesLinks = this.pagination(data.paginate, this.currentPage).pagesLinks;
                        this.isFirstPage = this.pagination(data.paginate).isFirstPage;
                        this.isLastPage = this.pagination(data.paginate).isLastPage;
                        this.lastPageID = this.pagination(data.paginate).lastPageID;

                    }
                }

                if (res?.status == 500) {
                    this.$router.push("/500");
                    return;
                }

                window.scroll({
                    top: 0,
                    left: 0,
                    behavior: "smooth",
                });
            });
        },
        suggestedEvent() {
            getSuggestedEvent().then(res => {
                // console.log(res);
                if (res.status == 200) {
                    const data = res.data?.data?.event;
                    // console.log(data);
                    this.suggested = data.find((e, i) => i == 0);
                }

                if (res?.response?.status == 500) {
                    this.$router.push("/500");
                    return;
                }

                this.loadingSuggested = false;
            });
        },
        fetchPage(id) {

            if (typeof(id) == 'number') {
                this.currentPage = id;
                this.getAllEvents();
            } 

            if (id == 'prev' && !this.isFirstPage) {
                this.currentPage = this.currentPage - 1;
                this.getAllEvents();
            }

            if (id == 'next' && !this.isLastPage) {
                this.currentPage = this.currentPage + 1;
                this.getAllEvents();
            }
        },
        checkLogin(eventID, eventName) {
            if (!this.$store.state.auth?.isLogin) {
                this.$root.$emit("bv::show::modal", "must-login-modal");
                return;
            }

            this.$router.push(`/events/${ eventID }/${ eventName }`);
        }
    },
    mounted() {
        this.getAllEvents();
        this.suggestedEvent();
    }
};
</script>
